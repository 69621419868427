<template>
  <div id="stop_area">
    <div class="admin_main_block">
      <div class="user_temp_title">快递停发区域</div>
      <div class="admin_table_main">
        <div class="storage_vip_list clearfix">
          <span
            @click="(storeId = item.id), getDataByStore(item.id)"
            :class="storeId == item.id ? 'red' : ''"
            v-for="item in stores"
            >{{ item.name }}</span
          >
        </div>
        <table
          border="0"
          align="center"
          cellpadding="0"
          cellspacing="0"
          v-loading="loading"
        >
          <tbody>
            <tr data-v-125a1f6c="">
              <td
                width="100"
                height="35"
                align="center"
                style="border-bottom: 1px solid rgb(222, 222, 222)"
              >
                区域类型
              </td>
              <td
                width="222"
                height="35"
                align="center"
                style="border-bottom: 1px solid rgb(222, 222, 222)"
              >
                省份
              </td>
              <td
                width="267"
                align="center"
                style="border-bottom: 1px solid rgb(222, 222, 222)"
              >
                城市
              </td>
              <td
                width="231"
                align="center"
                style="border-bottom: 1px solid rgb(222, 222, 222)"
              >
                区/县
              </td>
              <td
                width="531"
                align="center"
                style="border-bottom: 1px solid rgb(222, 222, 222)"
              >
                停发原因
              </td>
            </tr>
            <tr v-for="(item, key) in list">
              <td>
                {{
                  item.areaType == 1
                    ? "省级"
                    : item.areaType == 2
                    ? "市级"
                    : "区级"
                }}
              </td>
              <td
                colspan="3"
                align="center"
                style="
                  border-bottom: 1px solid rgb(222, 222, 222);
                  border-right: 1px solid rgb(222, 222, 222);
                  padding: 10px 0px;
                "
              >
                {{ item.provinceName }} &nbsp;&nbsp;
                {{ item.cityName }} &nbsp;&nbsp; {{ item.expAreaName }}
              </td>
              <td>{{ item.reason }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StopArea",

  data() {
    return {
      loading: "",
      storeId: "",
      times: new Date(),
      stores: [],
      areaList: [],
      list: [],
    };
  },

  created() {},
  mounted() {
    this.getStoreList();

  },
  watch: {},
  methods: {

    getStoreList() {
      this.$request.post({
        url: "/ReportApi/business/goodsStore/getStoreInfo",
        params: {},
        success: (res) => {
          this.stores = res;

          this.getList();
        },
      });
    },
    getDataByStore(storeId) {
      this.list = this.areaList.filter((item) => item.storeId == storeId);
    },

    getList() {
      this.storeId = "";
      this.$request.post({
        url: "/ReportApi/business/goodsStore/getStopAreaInfo",
        params: {
          storeId: this.storeId,
        },
        success: (res) => {
          for (let i = 0; i < res.length; i++) {
            delete res[i].id;
            delete res[i].status;

            res[i].areaType = res[i].area_type;
            delete res[i].area_type;
            res[i].cityName = res[i].area_name;
            delete res[i].area_name;
            res[i].createTime = res[i].create_time;
            delete res[i].create_time;
            res[i].expAreaName = res[i].exp_area_name;
            delete res[i].exp_area_name;
            res[i].provinceName = res[i].province_name;
            delete res[i].province_name;
            res[i].storeId = res[i].store_id;
            delete res[i].store_id;
          }
          this.areaList = res;

          this.list = this.areaList;
          if (this.stores.length > 0) {
            this.storeId = this.stores.data.id;
            this.getDataByStore(this.storeId);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#stop_area {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    min-height: 855px;
    border-radius: 5px;

    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .admin_table_main {
      .vip_type {
        margin-left: 50px;
        line-height: 60px;
        font-size: 18px;
      }

      .storage_vip_list {
        margin-left: 50px;
        margin-bottom: 10px;

        span {
          width: 165px;
          line-height: 40px;
          border: 1px solid #dedede;
          border-radius: 5px;
          margin-right: 10px;
          display: block;
          height: 40px;
          text-align: center;
          color: #666;
          float: left;
          cursor: pointer;

          &.red {
            color: white;
            background-color: #ea242f;
            border-color: #ea242f;
          }
        }
      }

      table {
        margin: 10px auto 0;
        font-size: 14px;
        width: 880px;
        color: rgb(119, 119, 119);
        border-left: 1px solid rgb(222, 222, 222);

        tbody {
          tr {
            &:first-child {
              background: #ea242f;
              color: white;

              td {
                border-right: none;
                height: 35px;

                &:nth-child(3) {
                  color: white;
                }
              }
            }

            td {
              height: 40px;
              text-align: center;
              border-bottom: 1px solid rgb(222, 222, 222);
              border-right: 1px solid rgb(222, 222, 222);



            }
          }
        }
      }

      .lastdiv {
        margin-left: 50px;
        background: #ea242f;
        color: white;
        line-height: 40px;
        text-align: center;
        width: 880px;
        border-radius: 5px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
